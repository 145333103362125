"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import authAPI from "@/services/auth_api";
import { definitions } from "../services/metruyenvl";
import { useDispatch, useSelector } from "react-redux";
import { setBannerConfig, themeColor } from "@/redux/reducers/theme.reducer";
import { authorInfo, getConfigBanner } from "@/redux/reducers/author.reducer";
import { Skeleton } from "antd";
type IProps = {
    url?: string;
};
interface IBanner {
    BannerID: string;
}

const MainBanner = (props: IProps) => {
    const dispatch = useDispatch();
    const bannerConfig = useSelector(themeColor);
    const [backgroundImage, setBackgroundImage] = useState<definitions["models.BannerMD"]>({
        id: "",
        alt: "alt for banner",
        book_id: "book_id",
        direct_url: "/",
        height: 100,
        image: "https://static.cdnno.com/storage/topbox/f92746ad1bd7c31ef316f4e73e84d775.webp",
        width: 100,
        created_time: 0,
        updated_time: 0,
    });
    // const backgroundImage = {
    //     id: "",
    //     alt: "alt for banner",
    //     book_id: "book_id",
    //     direct_url: "/",
    //     height: 338,
    //     image: "https://static.cdnno.com/storage/topbox/f92746ad1bd7c31ef316f4e73e84d775.webp",
    //     width: 0,
    //     created_time: 0,
    //     updated_time: 0,
    // } as definitions["models.BannerMD"];

    useEffect(() => {
        const getBannerConfig = async () => {
            try {
                const res = await authAPI.getBanner();
                const data = res.data as definitions["models.BannerMD"][];
                dispatch(setBannerConfig(data[0]));
            } catch (error) {
                console.log(error);
            }
        };
        // dispatch(getConfigBanner());
        if (bannerConfig.bannerImage.image === "") {
            getBannerConfig();
        }
    }, []);

    return (
        <div className="w-full md:block hidden">
            {!!bannerConfig && !!bannerConfig?.bannerImage && !!bannerConfig?.bannerImage?.image ? (
                <a href={bannerConfig?.bannerImage?.direct_url}>
                    <Image unoptimized src={bannerConfig?.bannerImage?.image} width={100} height={320} className="h-80 w-full" alt="" />
                    {/* <Image unoptimized={true} src={backgroundImage.image as string} height={100} width={100} alt={backgroundImage.alt as string} className={"bg-cover bg-center bg-no-repeat -z-10 left-0 h-80 w-full" + backgroundImage && (backgroundImage.width as number) > 0 ? "" : " w-full"}></Image> */}
                </a>
            ) : (
                <Skeleton active style={{ width: "100%", height: 320 }} title={false} paragraph={{ rows: 10 }} />
            )}
        </div>
    );
};

export default MainBanner;
