import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { DesCard, TitleCard, NormalStyles, GenreCard, HeadModule } from "@/layouts/styles/ssr.style";
import { BiUser } from "react-icons/bi";
import { displayNumber, renderContentWithLineBreaks } from "@/utils/formatted";
import { definitions } from "@/services/metruyenvl";
import BookCover from "./book-cover.component";
import { Popover, Skeleton } from "antd";
import bookAPI from "@/services/book_api";
import { ResponseTypes } from "@/types/constants";

function CardBookRate() {
    const BOOKS_LIMIT = 16;
    const router = useRouter();
    const [books, setListBooksRatingRank] = useState<definitions["do.BookDO"][]>([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res: ResponseTypes = await bookAPI.getAll({
                    orderBy: "review_point",
                    page: 1,
                    pageSize: BOOKS_LIMIT,
                    withStats: true,
                });
                setListBooksRatingRank(res.data as definitions["do.BookDO"][]);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    const renderSkeletons = () => {
        return [...Array(BOOKS_LIMIT - 2)].map((_, i) => (
            <div key={i} className="w-full rounded-xl bg-amber mb-3">
                <div className="flex items-center gap-x-4">
                    <Skeleton.Image active style={{ width: 80, height: 150 }} className="w-auto" />
                    <div className="flex flex-col items-start w-full">
                        <Skeleton.Input active style={{ width: "100%" }} block />
                        <div className="mt-2 pr-1 flex w-full">
                            <Skeleton.Avatar active size="default" className="w-auto" />
                            <Skeleton.Input active style={{ width: "80%" }} className="ml-2" block />
                        </div>
                        <Skeleton.Input style={{ width: "90%" }} className="mt-1" block />
                        <div className="mt-2 pr-1 flex w-full justify-between">
                            <Skeleton.Avatar active size="default" className="w-auto" />
                            <Skeleton.Button active />
                            <Skeleton.Button active className="ml-4 text-end" />
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <>
            <HeadModule>Đánh giá cao</HeadModule>
            <div className="grid md:grid-cols-2 grid-cols-2 gap-5 gap-y-8">
                {books.length === 0
                    ? renderSkeletons()
                    : books.map((book: definitions["do.BookDO"], i: number) => (
                          <div key={book.id} className={`${i > 9 ? "md:hidden xl:block" : ""}`}>
                              <div className="xl:flex gap-x-3 w-full justify-between">
                                  <Popover content={book.name} placement="top">
                                      <div
                                          className="md:mb-0 mb-2 cursor-pointer"
                                          onClick={() => {
                                              router.push(`/truyen/${book.slug}`);
                                          }}
                                      >
                                          <BookCover src={book.thumbnail as string} alt={book.name as string} size="medium" />
                                      </div>
                                  </Popover>

                                  <div className="flex w-full flex-col justify-between">
                                      <TitleCard
                                          click={() => {
                                              router.push(`/truyen/${book.slug}`);
                                          }}
                                      >
                                          <Popover content={book.name} placement="top">
                                              {book.name}
                                          </Popover>
                                      </TitleCard>
                                      <div className="flex gap-x-6 items-center mt-2 mb-2">
                                          <div className="rounded-full font-semibold p-1 text-xs lg:text-base pr-2 pl-2 bg-red-700 text-white">{displayNumber(!!book.stats?.reviews_point && book.stats?.reviews_point > 0 ? book.stats?.reviews_point : 5)}</div>
                                          <div className="text-blue-700 text-xs lg:text-base ">{!!book.stats?.reviews_count && book.stats?.reviews_count > 0 ? book?.stats?.reviews_count : 0} đánh giá</div>
                                      </div>
                                      <DesCard>
                                          <div dangerouslySetInnerHTML={{ __html: renderContentWithLineBreaks(book.description) }} className="w-full h-8 md:h-auto" />
                                      </DesCard>

                                      <div className="flex flex-col md:flex-row w-full justify-between items-center mt-2">
                                          <div
                                              className="flex items-center gap-1 cursor-pointer md:mb-0 mb-3"
                                              onClick={() => {
                                                  router.push(`/tac-gia/${book.author?.slug}`);
                                              }}
                                          >
                                              <BiUser />
                                              <NormalStyles>
                                                  <Popover content={book.author?.name} placement="top">
                                                      {book.author?.name}
                                                  </Popover>
                                              </NormalStyles>
                                          </div>
                                          <GenreCard
                                              click={() => {
                                                  const category = JSON.parse(localStorage.getItem("category") ?? "");
                                                  const lastCategory = category.pop();
                                                  router.push(`/the-loai/${book.tags?.[0].slug ?? lastCategory.slug}/${book.tags?.[0].id ?? lastCategory.id}`);
                                              }}
                                          >
                                              <Popover content={book.tags?.[0].name ?? "Khác"} placement="top">
                                                  {book.tags?.[0].name ?? "Khác"}
                                              </Popover>
                                          </GenreCard>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      ))}
            </div>
        </>
    );
}

export default CardBookRate;
