"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import bookAPI from "@/services/book_api";
import { FaGlasses, FaUserPen } from "react-icons/fa6";
import { BsBook } from "react-icons/bs";
import { HeadModule } from "@/layouts/styles/ssr.style";
import { IDetailStory, ResponseTypes } from "@/types/constants";
import { TitleCard, NormalStyles, ShowMore } from "@/layouts/styles/ssr.style";
import { definitions } from "@/services/metruyenvl";
import BookCover from "./book-cover.component";
import { Popover, Skeleton } from "antd";
import { orderByToVi, slugToOrderBy } from "@/utils/stripHtml";

function RankList({ title, orderBy }: { title?: string; orderBy?: "read_count" | "comments" | "reviews" | "review_point" | "bookmarks" | "react_count" | "suggest_point" | "chapters_count" }) {
    const BOOKS_LIMIT = 20;
    const router = useRouter();
    const [listRank, setListRank] = useState<definitions["do.BookDO"][]>([]);
    useEffect(() => {
        const getData = async () => {
            const res: ResponseTypes = await bookAPI.getAll({ orderBy: orderBy, withStats: true, pageSize: BOOKS_LIMIT });
            setListRank(res.data);
        };
        getData();
    }, [orderBy]);

    const handleRanking = (index: number) => {
        switch (index) {
            case 0:
                return <Image unoptimized={true} height={25} width={25} src={"/rank-hight.png"} alt="" style={{ width: "20px", height: "20px" }} />;
            case 1:
                return <Image unoptimized={true} height={25} width={25} src={"/rank-medium.png"} alt="" style={{ width: "20px", height: "20px" }} />;
            case 2:
                return <Image unoptimized={true} height={25} width={25} src={"/rank-low.png"} alt="" style={{ width: "20px", height: "20px" }} />;
            default:
                return (
                    <div className="text-center">
                        <TitleCard styles={{ width: "20px" }}>{index + 1}</TitleCard>
                    </div>
                );
        }
    };

    const renderSkeletons = () => {
        return (
            <>
                <div className="flex justify-between gap-x-3 mt-3 mb-3 pb-4">
                    <div className="flex justify-between w-full gap-x-5 h-24">
                        <div className="w-full">
                            <Skeleton active loading avatar={{ shape: "circle", size: "small" }} title={{ width: "60%" }} paragraph={{ rows: 2, width: ["100%", "80%"] }} />
                        </div>
                        <Skeleton.Image active style={{ width: 80, height: 120 }} />
                    </div>
                </div>
                {[...Array(BOOKS_LIMIT - 3)].map((_, i) => (
                    <div key={i} className="flex items-center justify-between gap-x-3 pb-2 border-b border-inherit border-solid w-full pt-2">
                        <Skeleton.Avatar active size="small" />
                        <div className="xl:w-64 md:w-36 w-60">
                            <Skeleton.Input active size="small" />
                        </div>
                        <Skeleton.Input active size="small" />
                    </div>
                ))}
            </>
        );
    };
    const renderBook = (book: definitions["do.BookDO"], index: number) => (
        <div className="flex justify-between gap-x-3 mt-3 mb-3" key={book.slug}>
            {handleRanking(index)}
            {index === 0 ? (
                <div className="flex justify-between w-full gap-x-5 h-24">
                    <div>
                        <TitleCard
                            click={() => {
                                router.push(`/truyen/${book.slug}`);
                            }}
                        >
                            <Popover content={book.name} placement="top">
                                {book.name}
                            </Popover>
                        </TitleCard>
                        <div className="flex gap-x-2 items-center">
                            <FaGlasses color="#e77c0d" />
                            <NormalStyles>{book?.stats?.reading_count ?? 0} lượt đọc</NormalStyles>
                        </div>
                        <Popover content={`Tác giả: ` + book.author?.name?.toLocaleString()} placement="top">
                            <div
                                onClick={() => {
                                    router.push(`/tac-gia/${book.author?.slug}`);
                                }}
                                className="flex gap-x-2 items-center mt-1 cursor-pointer"
                            >
                                <FaUserPen color="#E91E63" />
                                <NormalStyles>{book.author?.name?.toLocaleString()}</NormalStyles>
                            </div>
                        </Popover>
                        <Popover content={`Thể loại: ` + (book.tags?.[0]?.name?.toLocaleString() ?? "Khác")} placement="top">
                            <div
                                onClick={() => {
                                    if (book.tags) {
                                        router.push(`/the-loai/${book.tags?.[0]?.slug}/${book.tags?.[0]?.id}`);
                                    }
                                }}
                                className="flex gap-x-2 items-center mt-1 cursor-pointer"
                            >
                                <BsBook color="#5988F3" />
                                <NormalStyles>{book.tags?.[0]?.name?.toLocaleString() ?? "Khác"}</NormalStyles>
                            </div>
                        </Popover>
                    </div>
                    <BookCover src={book.thumbnail as string} alt={book.name as string} size="small" />
                </div>
            ) : (
                <Popover content={book.name + ` | ` + (book?.stats?.reading_count ?? 0) + ` ` + orderByToVi(orderBy)} placement="top">
                    <div className="flex items-center justify-between gap-x-3 pb-2 border-b border-inherit border-solid w-full">
                        <div className="xl:w-64 md:w-36 w-60">
                            <TitleCard
                                click={() => {
                                    router.push(`/truyen/${book.slug}`);
                                }}
                            >
                                {book.name}
                            </TitleCard>
                        </div>
                        <NormalStyles>{(book?.stats?.reading_count ?? 0) + ` ` + orderByToVi(orderBy)}</NormalStyles>
                    </div>
                </Popover>
            )}
        </div>
    );

    return (
        <div className="md:shadow-xl shadow-[#d7d4ce] shadow-none xl:w-full bg-white md:p-6 p-4 overflow-hidden md:rounded-lg md:mt-6 mt-0 md:mb-6 xl:hover:scale-105 transition">
            <div className="flex items-center justify-between">
                <HeadModule>{title}</HeadModule>
                <ShowMore
                    click={() => {
                        router.push(`/xep-hang/${orderBy}`);
                    }}
                    styles={{ marginBottom: "24px" }}
                >
                    Xem tất cả
                </ShowMore>
            </div>
            {listRank?.length === 0 ? renderSkeletons() : listRank?.map(renderBook)}
        </div>
    );
}

export default RankList;
