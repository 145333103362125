"use client";
import React from "react";
import { useRouter } from "next/navigation";
import { IoStatsChartSharp, IoSend } from "react-icons/io5";
import { ImAddressBook } from "react-icons/im";
import { useSelector } from "react-redux";
import { themeColor } from "@/redux/reducers/theme.reducer";

function TopNavbarMob() {
    const router = useRouter();
    const customTheme = useSelector(themeColor);
    return (
        <div className="md:hidden h-12 w-full flex items-center justify-around text-white font-semibold" style={{ background: customTheme.contentColor }}>
            <div
                className="flex items-center gap-x-2"
                onClick={() => {
                    router.push("/tac-gia");
                }}
            >
                <ImAddressBook color="white" /> Tác giả
            </div>
            <div
                className="flex items-center gap-x-2"
                onClick={() => {
                    router.push("/xep-hang/doc-nhieu");
                }}
            >
                <IoStatsChartSharp color="white" /> Xếp hạng
            </div>
            <div
                className="flex items-center gap-x-2"
                onClick={() => {
                    router.push("/admin");
                }}
            >
                <IoSend color="white" /> Đăng truyện
            </div>
        </div>
    );
}

export default TopNavbarMob;
