import * as cheerio from "cheerio";

export const stripHtml = (html: string) => {
    if (!html) return "";
    const $ = cheerio.load(html);
    return $.text();
};

export const slugToOrderBy = (value: any) => {
    switch (value) {
        case "doc-nhieu":
            return "read_count";
        case "binh-luan":
            return "comments";
        case "luot-danh-gia":
            return "reviews";
        case "danh-gia-cao":
            return "review_point";
        case "danh-dau":
            return "bookmarks";
        case "tuong-tac":
            return "react_count";
        case "de-cu":
            return "suggest_point";
        case "so-chuong":
            return "chapters_count";
        default:
            return value;
    }
};

export const orderByToVi = (value: any) => {
    switch (value) {
        case "read_count":
            return "lượt đọc";
        case "comments":
            return "bình luận";
        case "reviews":
            return "lượt đánh giá";
        case "review_point":
            return "điểm đánh giá";
        case "bookmarks":
            return "đánh dấu";
        case "react_count":
            return "tương tác";
        case "suggest_point":
            return "đề cử";
        case "chapters_count":
            return "số chương";
        default:
            return value;
    }
};

export const orderBySlugToVi = (value: any) => {
    switch (value) {
        case "doc-nhieu":
            return "đọc nhiều";
        case "binh-luan":
            return "bình luận";
        case "luot-danh-gia":
            return "lượt đánh giá";
        case "danh-gia-cao":
            return "điểm đánh giá";
        case "danh-dau":
            return "đánh dấu";
        case "tuong-tac":
            return "tương tác";
        case "de-cu":
            return "đề cử";
        case "so-chuong":
            return "số chương";
        default:
            return value;
    }
};
