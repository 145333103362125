"use client";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { HeadModule, TitleCard } from "@/layouts/styles/ssr.style";

import CarouselItem from "./components/carousel-item";
import bookAPI from "@/services/book_api";
import { definitions } from "@/services/metruyenvl";
import AnimatedGradientTag from "@/components/animated-tag-gradient";
import { ResponseTypes } from "@/types/constants";
import BookCover from "@/components/book-cover.component";
import { useRouter } from "next/navigation";
import { Popover, Skeleton } from "antd";

function SliderModule() {
    const router = useRouter();
    const [listBooks, setList] = useState<definitions["do.BookDO"][]>();
    useEffect(() => {
        const getData = async () => {
            const res: ResponseTypes = await bookAPI.getAll({ orderBy: "review_point", period: "day", withStats: true, page: 1, pageSize: 10 });
            setList(res.data);
        };
        getData();
    }, []);
    var settings: any = {
        dots: true,
        // infinite: true,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                },
            },
        ],
    };

    return (
        <div className="mb-12 w-full overflow-hidden p-4 pb-0">
            <div className="flex items-center gap-x-4">
                <HeadModule>Nổi bật trong ngày</HeadModule>
                <div className="hidden md:block">
                    <AnimatedGradientTag tag="In day" />
                </div>
            </div>
            {!!listBooks ? (
                <div className="lg:h-[530px] h-[380px] ">
                    <Slider {...settings}>
                        {listBooks?.map((book) => {
                            return <CarouselItem books={book} key={book.slug} name={book.name} category={book.tags?.[0].name} author={book.author?.name} avatarUrl={book.thumbnail} slug={book.slug} score={book?.stats?.reviews_point ?? 5} />;
                        })}
                    </Slider>
                </div>
            ) : (
                <div className="lg:h-[530px] h-[380px]">
                    <Skeleton active paragraph={{ rows: 15 }} />
                </div>
            )}
        </div>
    );
}
export async function getServerSideProps() {
    // Fetch data from external API
    const res = await fetch("https://jsonplaceholder.typicode.com/posts/1");
    const data = await res.json();
    // Pass data to the page via props
    return { props: { data } };
}

export default SliderModule;
