"use client";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { getReadingsByBook } from "@/redux/reducers/book.reducer";
import { useAuth } from "@/hooks";
import CardMedium from "./card-medium";
import { IDetailStory, ResponseTypes } from "@/types/constants";
import bookAPI from "@/services/book_api";
import readAPI from "@/services/read_api";
import { definitions } from "@/services/metruyenvl";
import CardBook from "./card-book";

function CurrentReading() {
    // const dispatch = useDispatch<AppDispatch>();
    const [listPropose, setListPropose] = useState<definitions["do.BookDO"][]>([]);
    // const listReadings = useSelector((state: RootState) => state.bookReducer.listReadStores);
    const { isAuth, listReadings } = useAuth();

    const BOOKS_LIMIT = 6;

    useEffect(() => {
        const getData = async () => {
            try {
                const res: ResponseTypes = await bookAPI.getAll({
                    status: "finish",
                    page: 1,
                    pageSize: BOOKS_LIMIT,
                });
                setListPropose(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, [isAuth]);
    return (
        <div>
            <div className="md:mb-6 mb-3 text-black font-semibold xl:text-lg">{"Đã hoàn thành"}</div>
            <CardBook books={listPropose} booksSizeExpected={BOOKS_LIMIT} hiddenGenres={true} size="small" />
            {/* {listReadings
                ? listReadings?.map((item: definitions["do.BookDO"], index: number) => {
                      if (index >= 9) {
                          return;
                      }
                      return <CardMedium key={item.id} detail={item} />;
                  })
                : listPropose?.map((item: definitions["do.BookDO"], index: number) => {
                      if (index >= 9) {
                          return;
                      }
                      return <CardMedium key={item.id} detail={item} />;
                  })} */}
        </div>
    );
}

export default CurrentReading;
