"use client";
import React, { useState } from "react";
import { DetailCard } from "@/data";
import { useAuth } from "@/hooks";
import { useRouter } from "next/navigation";
import Image from "next/image";
import { BsTrash } from "react-icons/bs";
import { formatStringSlug, renderContentWithLineBreaks } from "@/utils/formatted";
import { useDispatch } from "react-redux";
import { DesCard, TitleCard, NormalStyles, GenreCard } from "@/layouts/styles/ssr.style";
import { BiUser } from "react-icons/bi";
import { definitions } from "@/services/metruyenvl";
import BookCover from "./book-cover.component";
import { Popover, Skeleton } from "antd";
import bookAPI from "@/services/book_api";
import bookmarkAPI from "@/services/bookmark_api";
import ConfirmDeleteModal from "./confirm-delete-modal";
import { Constants } from "@/types/constants";
function CardBook({ books, booksSizeExpected, showDeleteBtn, reloadData, deleteFunc, hiddenGenres = false, size = "small" }: { books: definitions["do.BookDO"][]; booksSizeExpected: number; showDeleteBtn?: boolean; reloadData?: any; deleteFunc?: any; hiddenGenres?: boolean; size?: string }) {
    const router = useRouter();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const removeBookmark = async (id?: string) => {
        try {
            const res = await bookmarkAPI.delete(id ?? "");
            reloadData();
        } catch (error) {
            console.log(error);
        }
    };

    const renderSkeletons = () => {
        return [...Array(booksSizeExpected)].map((_, i) => (
            <div key={i} className="flex gap-x-3 w-full justify-between p-2 md:mb-0">
                <Skeleton
                    active={true}
                    loading={true}
                    avatar={{ shape: "square" }}
                    title={{ width: 250 }}
                    paragraph={{
                        rows: 2,
                        width: [250, 100],
                    }}
                />
            </div>
        ));
    };

    const renderBook = (book: definitions["do.BookDO"]) => (
        <div key={book.id} className="flex gap-x-3 w-full justify-between p-2 md:mb-0">
            <ConfirmDeleteModal
                isVisible={isVisible}
                title="Bạn có chắc chắn muốn xoá truyện này không?"
                cancelCallback={() => {
                    setIsVisible(false);
                }}
                confirmCallback={() => {
                    deleteFunc();
                    setIsVisible(false);
                }}
            />
            <div
                className="object-cover md:w-20 cursor-pointer"
                onClick={() => {
                    router.push(`/truyen/${book.slug}`);
                }}
            >
                <BookCover src={(book.thumbnail as string) ?? Constants.DefaultBookThumbnail} alt={book.name as string} size={size} />
            </div>

            <div className="flex w-full flex-col justify-between">
                <div className="flex justify-between items-center">
                    <TitleCard
                        click={() => {
                            router.push(`/truyen/${book.slug}`);
                        }}
                    >
                        <Popover content={book.name} placement="top">
                            {book.name}
                        </Popover>
                    </TitleCard>
                    <div className="cursor-pointer">
                        {showDeleteBtn && (
                            <BsTrash
                                color="red"
                                size={18}
                                onClick={() => {
                                    setIsVisible(true);
                                }}
                            />
                        )}
                    </div>
                </div>
                <DesCard>
                    <div dangerouslySetInnerHTML={{ __html: renderContentWithLineBreaks(book.description) }}></div>
                </DesCard>
                <div className="flex justify-between items-center mt-2">
                    <div
                        className="flex items-center gap-1 cursor-pointer"
                        onClick={() => {
                            router.push(`/tac-gia/${book.author?.slug}`);
                        }}
                    >
                        <BiUser />
                        <NormalStyles>
                            <Popover content={book.author?.name} placement="top">
                                {book.author?.name}
                            </Popover>
                        </NormalStyles>
                    </div>
                    {!hiddenGenres && (
                        <div>
                            <GenreCard
                                click={() => {
                                    const category = JSON.parse(localStorage.getItem("category") ?? "");
                                    const lastCategory = category.pop();
                                    router.push(`/the-loai/${book.tags?.[0].slug ?? lastCategory.slug}/${book.tags?.[0].id ?? lastCategory.id}`);
                                }}
                            >
                                <Popover content={book.tags?.[0].name ?? "Khác"} placement="top">
                                    {book.tags?.[0].name ?? "Khác"}
                                </Popover>
                            </GenreCard>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <>
            {books?.length === 0 && renderSkeletons()}
            {books?.map(renderBook)}
        </>
    );
}

export default CardBook;
