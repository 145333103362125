"use client";
import React from "react";
import CurrentReading from "@/components/current-reading";
import TopNavbarMob from "./components/top-navbar-mob";
import EditorNomonation from "./components/editor-nomonation";

function TopNomonation() {
    return (
        <div>
            <TopNavbarMob />
            <div className="bg-white rounded-lg mt-0 md:mt-[-120px] md:grid grid-cols-3 gap-x-6 grid-flow-col p-4 md:p-6">
                <div className="col-span-2">
                    <EditorNomonation />
                </div>
                <div className="col-span-1">
                    <CurrentReading />
                </div>
            </div>
        </div>
    );
}

export default TopNomonation;
