"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import commentAPI from "@/services/comment_api";
import bookAPI from "@/services/book_api";
import SectionNewRate from "./components/section-new-rate";
import CardBookRate from "@/components/card-book-rate";
import { HeadModule } from "@/layouts/styles/ssr.style";
import { definitions } from "@/services/metruyenvl";
import { ResponseTypes } from "@/types/constants";

const RateHight = () => {
    return (
        <div className="md:grid md:grid-cols-3 gap-x-6 grid-flow-col p-4 md:p-6">
            <div className="col-span-2">
                <CardBookRate />
            </div>
            <div className="col-span-1">
                <SectionNewRate />
            </div>
        </div>
    );
};

export default RateHight;
