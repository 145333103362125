"use client";
import React from "react";
import { Popover, Skeleton, Table } from "antd";
import { useRouter } from "next/navigation";
import ItemTableSection from "./components/item-table";
import { formatDistanceToNow } from "date-fns";
import { vi } from "date-fns/locale";

import { NormalStyles, TitleCard, HeadModule, ShowMore, NormalStylesWithClick } from "@/layouts/styles/ssr.style";
import { definitions } from "@/services/metruyenvl";
import AnimatedGradientTag from "@/components/animated-tag-gradient";
import { FaUserEdit } from "react-icons/fa";
import { BiBookBookmark } from "react-icons/bi";

function BooksLatestUpdateList({ latestUpdate }: { latestUpdate: definitions["do.BookUpdateLatestRes"][] }) {
    const router = useRouter();
    const columns: any = [
        {
            title: "Tag",
            dataIndex: "tag",
            key: "tag",

            render: (tag: string, book: definitions["do.BookUpdateLatestRes"]) => {
                return (
                    <NormalStylesWithClick
                        click={() => {
                            router.push(`/truyen/${book.slug}`);
                            // router.push(`/truyen?the-loai=${book.genre_id}`);
                        }}
                    >
                        {tag ?? "Khác"}
                        {/* <Popover content={tag ?? "Khác"} placement="top">
                        </Popover> */}
                    </NormalStylesWithClick>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: string, book: definitions["do.BookUpdateLatestRes"]) => (
                <TitleCard
                    click={() => {
                        router.push(`/truyen/${book.slug}`);
                    }}
                >
                    <Popover content={name} placement="top">
                        {name}
                    </Popover>
                </TitleCard>
            ),
        },
        {
            title: "Chapter",
            dataIndex: "chapter_name",
            key: "chapter_name",
            render: (chapterName: string, book: definitions["do.BookUpdateLatestRes"]) => {
                return (
                    <NormalStylesWithClick
                        click={() => {
                            router.push(`/truyen/${book.slug}/${book.chapter_slug}`);
                        }}
                    >
                        <Popover content={chapterName} placement="top">
                            {chapterName}
                        </Popover>
                    </NormalStylesWithClick>
                );
            },
        },
        {
            title: "Author",
            dataIndex: "author",
            key: "author",
            render: (author: string, book: definitions["do.BookUpdateLatestRes"]) => (
                <NormalStylesWithClick
                    click={() => {
                        router.push(`/tac-gia/${book.author_slug}`);
                    }}
                >
                    <Popover content={author} placement="top">
                        {author}
                    </Popover>
                </NormalStylesWithClick>
            ),
        },
        {
            title: "Publisher",
            dataIndex: "publisher",
            key: "publisher",
            render: (publisher: string, book: definitions["do.BookUpdateLatestRes"]) => (
                <NormalStylesWithClick
                    click={() => {
                        router.push(`/user?id=${book.publisher_id}`);
                    }}
                >
                    <Popover content={publisher} placement="top">
                        {publisher}
                    </Popover>
                </NormalStylesWithClick>
            ),
        },
        {
            title: "UpdatedTime",
            dataIndex: "updated_time",
            key: "updated_time",
            render: (updatedTime: number) => {
                const date = new Date(updatedTime * 1000);
                const distance = formatDistanceToNow(date, {
                    locale: vi,
                    addSuffix: true,
                });
                return (
                    <NormalStyles>
                        <Popover content={distance} placement="top">
                            {distance}
                        </Popover>
                    </NormalStyles>
                );
            },
        },
    ];

    const renderSkeletons = () => {
        return [...Array(10)].map((_, i) => (
            <div key={i} className="p-2 pl-0">
                <div className="flex justify-between items-center">
                    <Skeleton active={true} loading={true} avatar={false} title={{ width: 200 }} paragraph={false} />
                </div>
                <div className="flex items-center gap-x-2">
                    <Skeleton active={true} loading={true} avatar={false} title={{ width: 150 }} paragraph={false} />
                    <Skeleton active={true} loading={true} avatar={false} title={{ width: 100 }} paragraph={false} />
                </div>
            </div>
        ));
    };

    const renderBook = (book: definitions["do.BookUpdateLatestRes"]) => (
        <div key={book.id} className="p-2 pl-0">
            <div className="flex justify-between items-center">
                <TitleCard
                    click={() => {
                        window.location.href = `/truyen/${book.slug}`;
                    }}
                >
                    <Popover content={book.name} placement="top">
                        {book.name}
                    </Popover>
                </TitleCard>
            </div>
            <div className="flex items-center gap-x-2">
                <FaUserEdit color="#6b7280" />
                <NormalStyles>
                    <Popover content={book.author} placement="top">
                        {book.author}
                    </Popover>
                </NormalStyles>
                <BiBookBookmark color="#6b7280" />
                <NormalStyles>
                    <Popover content={book.tag} placement="top">
                        {book.tag}
                    </Popover>
                </NormalStyles>
            </div>
        </div>
    );

    return (
        <div className="md:mt-6 p-4 md:p-6 pb-0">
            <div>
                <div className="flex justify-between items-center md:mb-0 mb-[-10px]">
                    <div className="flex items-center gap-x-5">
                        <HeadModule>Mới cập nhật</HeadModule>
                        <AnimatedGradientTag tag="New" />
                    </div>
                    {/* <ShowMore
                            styles={{ marginBottom: "1.5rem" }}
                            click={() => {
                                router.push("/moi-nhat");
                            }}
                        >
                            Xem tất cả
                        </ShowMore> */}
                </div>
                <div className="md:block hidden">
                    <Table columns={columns} dataSource={latestUpdate} pagination={false} showHeader={false} rowKey={"id"} />
                </div>
            </div>
            <div className="md:hidden block mt-[-10px]">{latestUpdate?.length === 0 ? renderSkeletons() : latestUpdate?.map(renderBook)}</div>
        </div>
    );
}

export default BooksLatestUpdateList;
