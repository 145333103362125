import React, { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { Avatar, Skeleton } from "antd";
import { TitleCard, DesCard, HeadModule } from "@/layouts/styles/ssr.style";
import { DetailCard } from "@/data";
import { displayNumber, unixToDate } from "@/utils/formatted";
import { definitions } from "@/services/metruyenvl";
import commentAPI from "@/services/comment_api";
import { ResponseTypes } from "@/types/constants";
import { FaArrowsRotate } from "react-icons/fa6";

function SectionNewRate() {
    const ELEMENT_LIMIT = 8;
    const router = useRouter();

    const [listComment, setListComment] = useState<definitions["do.CommentDO"][]>([]);
    const [commentLoading, setCommentLoading] = useState<boolean>(true);

    const getNewComments = useCallback(async () => {
        setCommentLoading(true);

        try {
            const res: ResponseTypes = await commentAPI.getCommentNewest(ELEMENT_LIMIT);
            setListComment(res.data as definitions["do.CommentDO"][]);
        } catch (error) {
            console.log(error);
        }

        setCommentLoading(false);
    }, []);

    useEffect(() => {
        getNewComments();

        const interval = setInterval(() => {
            getNewComments();
        }, 15000);

        return () => clearInterval(interval);
    }, [getNewComments]);

    const renderSkeletons = () => {
        return [...Array(ELEMENT_LIMIT)].map((_, i) => (
            <div key={i} className="w-full h-40 rounded-xl bg-amber p-4 mb-3 mt-2" style={{ background: "#F7F5F0" }}>
                <div className="flex items-center gap-x-4">
                    <Skeleton.Avatar active size={45} />
                    <div className="w-full">
                        <div className="flex items-center gap-x-2 w-full">
                            <Skeleton.Input active size="small" />
                            <div className="text-slate-500 text-sm font-semibold md:block hidden">bình luận</div>
                        </div>
                        <Skeleton.Input active size="small" className="flex mt-1 w-full" />
                    </div>
                </div>
                <Skeleton active paragraph={{ rows: 1, width: ["100%", "80%"] }} className="mt-3" />
            </div>
        ));
    };

    return (
        <>
            <div className="w-full flex justify-between items-center md:mt-0 mt-4">
                <HeadModule styles={{ marginBottom: "0px" }}>Mới bình luận</HeadModule>{" "}
                <button onClick={getNewComments} className={commentLoading ? "hover:bg-slate-200 animate-spin font-bold p-2 rounded-full flex items-center justify-center" : "hover:bg-slate-200 hover:animate-spin font-bold p-2 rounded-full flex items-center justify-center"}>
                    <FaArrowsRotate />
                </button>
            </div>

            {listComment.length === 0
                ? renderSkeletons()
                : listComment.map((comment, index) => (
                      <div key={index} className="w-full h-40 rounded-xl bg-amber p-4 mb-3 mt-2" style={{ background: "#F7F5F0" }}>
                          <div className="flex items-center gap-x-4">
                              <div>
                                  <Avatar size={45} icon={<Image unoptimized={true} src={`${comment?.author?.avatar ?? "/avatar.gif"}`} alt={comment.author?.name as string} height={44} width={44} className="h-auto w-auto" />} />
                              </div>
                              <div className="flex flex-col items-start">
                                  <div className="flex items-center gap-x-2 justify-end">
                                      <TitleCard>{comment?.author?.name}</TitleCard>
                                      <div className="text-slate-500 text-sm font-semibold md:block hidden">bình luận</div>
                                  </div>
                                  <div
                                      onClick={() => {
                                          router.push(`/truyen/${comment?.book?.slug}`);
                                      }}
                                      className="text-red-600 font-semibold text-sm line-clamp-1 cursor-pointer"
                                  >
                                      {comment?.book?.name}
                                  </div>
                              </div>
                          </div>
                          <div
                              className="text-sm line-clamp-3 mt-3 text-gray-600"
                              onClick={() => {
                                  router.push(`/truyen/${comment?.book?.slug}`);
                              }}
                          >
                              {comment?.content}
                          </div>
                      </div>
                  ))}
        </>
    );
}

export default SectionNewRate;
