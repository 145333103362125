import React from "react";
import Image from "next/image";
import { Popover } from "antd";
import "./book-info.css";
import { Constants } from "@/types/constants";
function BookCover({ src, alt, size = "medium" }: { src: string; alt: string; size?: string }) {
    let bookClassName = "h-36 w-28";
    let width = 30;
    let height = 42;
    switch (size) {
        case "sm":
        case "small":
            bookClassName = "h-24 w-14";
            width = 20;
            height = 28;
            break;
        case "md":
        case "medium":
            bookClassName = "h-36 w-28";
            width = 30;
            height = 42;
            break;
        case "lg":
        case "large":
            bookClassName = "lg:h-64 lg:w-48 w-32 h-36";
            width = 40;
            height = 56;
            break;
        default:
            bookClassName = "h-36 w-28";
            width = 30;
            height = 42;
            break;
    }

    return (
        <Popover content={alt as string} placement="top">
            <div className="book-cover">
                <div className="book-inside block"></div>
                <div className="book-image overflow-hidden md:h-full m-auto">
                    {/* <img src={src ?? Constants.DefaultBookThumbnail} alt="" className="rounded md:h-24 md:w-1/2 w-full h-full m-auto md:m-0" /> */}
                    <Image src={src ?? Constants.DefaultBookThumbnail} alt={alt} width={width} height={height} quality={100} unoptimized={true} priority className={"rounded object-cover m-auto md:m-0 " + bookClassName} />
                    <div className="light"></div>
                </div>
            </div>
        </Popover>
    );
}

export default BookCover;
