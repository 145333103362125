"use client";
import React from "react";
import { HeadModule } from "@/layouts/styles/ssr.style";
import RankList from "@/components/rank-list";

function SectionRanking() {
    return (
        <div className="mt-6 md:grid grid-cols-3 xl:gap-x-8 md:gap-x-2">
            <RankList title="Đọc nhiều tuần" orderBy="read_count" />
            <RankList title="Tương tác tuần" orderBy="react_count" />
            <RankList title="Đề cử tuần" orderBy="suggest_point" />
        </div>
    );
}

export default SectionRanking;
