import React from "react";
import { useRouter } from "next/navigation";
import { Popover, Rate } from "antd";
import { BiUser } from "react-icons/bi";
import { TitleCard, NormalStyles, GenreCard } from "@/layouts/styles/ssr.style";
import BookCover from "@/components/book-cover.component";
import { displayNumber } from "@/utils/formatted";
import { definitions } from "@/services/metruyenvl";

interface ICarouselItem {
    name?: string;
    author?: string;
    category?: string;
    rating?: string;
    avatarUrl?: string;
    slug?: string;
    score?: number;
    books: definitions["do.BookDO"];
}

function CarouselItem({ name, category, author, rating, avatarUrl, slug, score, books }: ICarouselItem) {
    const router = useRouter();
    return (
        <div className="lg:h-[500px] h-[350px] p-5 rounded-lg content-center flex items-center flex-col">
            <div
                className="lg:h-[260px] lg:w-[200px] "
                onClick={() => {
                    router.push(`/truyen/${slug}`);
                }}
            >
                <BookCover src={avatarUrl as string} alt={name as string} size="large"></BookCover>
            </div>
            <div className="pt-6">
                <TitleCard
                    click={() => {
                        router.push(`/truyen/${slug}`);
                    }}
                >
                    <Popover content={name} placement="top">
                        {name}
                    </Popover>
                </TitleCard>
                <div className="flex items-center gap-x-3 mt-2">
                    <div className="rounded-full font-semibold w-fit p-1 pr-2 pl-2 bg-red-700 text-white">{displayNumber(score ?? 0)}</div>
                    <Rate count={5} value={score} allowHalf disabled />
                </div>
                <div className="flex justify-between items-center mt-2 gap-x-1">
                    <div
                        className="flex items-center gap-x-2 w-full cursor-pointer"
                        onClick={() => {
                            router.push(`/tac-gia/${books.author?.slug}`);
                        }}
                    >
                        <BiUser size={20} />
                        <NormalStyles>
                            <Popover content={author} placement="top">
                                {author}
                            </Popover>
                        </NormalStyles>
                    </div>
                </div>
                <div className="flex items-center mt-2">
                    {" "}
                    <GenreCard
                        click={() => {
                            const category = JSON.parse(localStorage.getItem("category") ?? "");
                            const lastCategory = category.pop();
                            router.push(`/the-loai/${books.tags?.[0].slug ?? lastCategory.slug}/${books.tags?.[0].id ?? lastCategory.id}`);
                        }}
                    >
                        <Popover content={category ?? "Khác"} placement="top">
                            {category ?? "Khác"}
                        </Popover>
                    </GenreCard>
                </div>
            </div>
        </div>
    );
}

export default CarouselItem;
