"use client";
import React, { useEffect, useState } from "react";
import CardBook from "@/components/card-book";
import bookAPI from "@/services/book_api";
import { definitions } from "@/services/metruyenvl";
import { ResponseTypes } from "@/types/constants";
import { Skeleton } from "antd";

function EditorNomonation() {
    const BOOKS_LIMIT = 10;
    const [books, setBooks] = useState<definitions["do.BookDO"][]>([]);
    useEffect(() => {
        const getData = async () => {
            const res: ResponseTypes = await bookAPI.getAllManga(8, BOOKS_LIMIT);
            setBooks(res.data);
        };
        getData();
    }, []);
    return (
        <div className="">
            <div className="md:mb-6 text-black font-semibold xl:text-lg">Biên tập viên để cử</div>
            <div className="w-full grid md:grid-cols-2 grid-cols-1 md:gap-5 gap-y-1 md:gap-y-8">
                <CardBook books={books} booksSizeExpected={BOOKS_LIMIT} />
            </div>
        </div>
    );
}

export default EditorNomonation;
